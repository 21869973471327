<template>
  <SimpleSpinner v-if="loadingLounge"/>
  <div v-if="!loadingLounge">
    <GeneralVoting :loungeDetails="getLoungeDetails" v-if="getLoungeState('GENERAL_VOTE')"/>
    <VotingFinished @goToRanking="goToRanking" :loungeDetails="getLoungeDetails" v-if="(getLoungeState('FINISHED_GENERAL_VOTE') || getLoungeState('FINISHED_RANKING')) && !finishedAlreadySeen"/>
    <Ranking :loungeDetails="getLoungeDetails" v-if="getLoungeState('RANKING') || finishedAlreadySeen"/>
    <Spinner v-if="getLoungeState('LOADING')" message="<div class='flex flex-col gap-2'><div>Esperando a que el DJ lance alguna votación.</div><div>¡Ve pensando qué canción te gustaría que suene!</div></div>"/>
    <Spinner v-if="getLoungeState('NOT_ACTIVE')" message="<div class='flex flex-col gap-2'><div>Esperando a que el DJ arranque la sesión TuParty.</div><div>¡No te impacientes!</div><div>¡Ve pensando en tu canción favorita!</div></div>"/>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';
import createLoungeService from '@/service/loungeService';
const SimpleSpinner = defineAsyncComponent(() => import('@/components/Common/SimpleSpinner.vue'));
const Spinner = defineAsyncComponent(() => import('../components/Common/Spinner.vue'));
const GeneralVoting = defineAsyncComponent(() => import('@/views/GeneralVoting.vue'));
const VotingFinished = defineAsyncComponent(() => import('./VotingFinished.vue'));
const Ranking = defineAsyncComponent(() => import('@/views/Ranking.vue'));

export default {
  name: 'Lounge',
  components: {
    SimpleSpinner,
    GeneralVoting,
    Ranking,
    VotingFinished,
    Spinner
  },
  setup() {
    const route = useRoute();
    const loungeId = ref(null);
    const loadingLounge = ref(false);
    const stateIndex = ref(null);
    const { fetchLoungeDetails } = createLoungeService();
    const loungeDetails = ref(null);
    const states = ['GENERAL_VOTE', 'RANKING', 'LOADING', 'FINISHED_GENERAL_VOTE', 'FINISHED_RANKING'];
    const pollingInterval = ref(null);
    const finishedAlreadySeen = ref(false);

    const fetchDetails = async () => {
      if (!loungeId.value) return;
      const details = await fetchLoungeDetails(loungeId.value);
      if (details && details.public) {
        loungeDetails.value = details;
        if (details.public.status !== 'FINISHED_RANKING' && details.public.status !== 'FINISHED_GENERAL_VOTE') finishedAlreadySeen.value = false
        stateIndex.value = states.indexOf(details.public.status);
      }
      loadingLounge.value = false;
    };

    onMounted(async () => {
      loadingLounge.value = true;
      loungeId.value = route.params.id;
      await fetchDetails();
      pollingInterval.value = setInterval(fetchDetails, 3000);
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval.value);
      pollingInterval.value = null;
    });

    const goToRanking = () => {
      finishedAlreadySeen.value = true
    };

    const getLoungeDetails = computed(() => loungeDetails.value);

    const getLoungeState = (state) => {
      return stateIndex.value === states.indexOf(state);
    };


    return {
      loungeId,
      loadingLounge,
      getLoungeState,
      getLoungeDetails,
      finishedAlreadySeen,
      goToRanking
    };
  }
};
</script>
