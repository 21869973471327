<template>
  <button
    :disabled="isLoading"
    class="button-spinner-btn"
  >
    <div class="flex justify-center items-center w-full" v-if="isLoading">
      <div class="button-spinner"></div>
    </div>
    <p class="font-semibold" v-if="!isLoading">{{ text }}</p>
    <slot v-if="!isLoading"></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    isLoading: Boolean,
    text: String
  }
  // <!-- class="relative flex justify-center items-center bg-tp-yellow py-3 px-16 w-max rounded-xl text-tp-black font-bold"> -->
};
</script>

<style>
.button-spinner-container {
  display: flex;

}
.button-spinner-btn {
  min-width: 100px;
}
.button-spinner {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>