<template>
  <div class="flex items-center justify-between bg-tp-yellow rounded-xl p-4">
    <div class="flex flex-col gap-2">
      <div class="flex items-center bg-tp-dark-green py-1 w-max rounded-lg px-4">
        <p class="text-xs">Ronda {{ index }}</p>
      </div>
      <div class="flex flex-col text-tp-dark-green">
        <p><strong>Canción ganadora:</strong> {{ roundInfo.winningSong }}</p>
        <p><strong>Votos:</strong> {{ roundInfo.votes }}€</p>
      </div>
    </div>
    <div class="flex justify-center items-center bg-tp-dark-green p-1 w-14 h-full rounded-lg px-3">
        <p>{{ roundInfo.revenue }}€</p>
      </div>
  </div>
  
</template>
  
  <script>

  export default {
    name: 'DJRoundInfo',
    props: {
      index: Number,
      roundInfo: Object
    },
    setup() {
      
      return {
      }
    }
  }
  
  
  </script>
  