<template>
  <div class="spinner-container">
    <p class="spinner-message" v-html="message"></p>
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    message: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 428px;
  padding: 0 24px;
}

.spinner-message {
  font-size: 1.25rem;
  font-weight: bold;
  max-width: 100%;
  white-space: normal;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
