<template>
  <NavBar />
  <div class="flex flex-col gap-8 px-8 py-4">
    <div class="flex flex-col gap-6">
      <p class="font-extrabold text-2xl">Detalles de la sesión <br> <span class="text-xl">{{ sessionDetails.date }}</span></p>
      <div class="flex gap-6 bg-tp-yellow text-tp-dark-green rounded-xl p-4">
        <div class="flex items-center justify-center bg-tp-dark-green p-4 rounded-lg">
          <img :src=wallet alt="" class="w-8 h-8">
        </div>
        <div class="flex flex-col justify-center">
          <p><strong>Total generado:</strong> 1200€</p>
          <p><strong>Beneficios:</strong> 960€</p>
        </div>
      </div>
    </div> 
    <div class="flex flex-col gap-6">
      <p class="font-extrabold text-xl">Rondas de votación</p>
      <div class="flex flex-col gap-4">
        <DJRoundInfo v-for="(round, index) in sessionDetails.votingRounds" :key="index" :roundInfo="round" :index="index+1" />
      </div>
    </div>
  </div>
</template>
  
  <script>
  import NavBar from '@/components/Common/NavBar.vue';
  import DJRoundInfo from '@/components/DJ/DJRoundInfo.vue';
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import wallet from '@/assets/images/wallet.png';
  
  export default {
    name: 'SessionDetails',
    components: {
      NavBar,
      DJRoundInfo
    },
    setup() {
      const route = useRoute();
  
      const sessionDetails = ref({
        id: route.params.id,
        revenue: 1200,
        date: '6/04/2024',
        votingRounds: [
          { winningSong: 'Canción A', votes: 150, revenue: 120 },
          { winningSong: 'Canción B', votes: 120, revenue: 100 },
          { winningSong: 'Canción C', votes: 100, revenue: 90 },
        ]
      });

      return {
        sessionDetails,
        wallet,
      }
    }
  }
  </script>
  
  