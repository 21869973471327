<template>
    <div class="flex flex-col items-center justify-between pt-16 pb-32 h-screen">
        <p class="text-tp-yellow text-5xl font-semibold text-center">TuParty</p>
        <p class="text-tp-yellow text-2xl font-semibold text-center">Hola,&nbsp; {{ name }}</p>
        <p class="text-tp-yellow text-2xl font-semibold text-center">Estamos encantados de haber recibido TuPetición de entrar</p>
        <p class="text-tp-yellow text-xl font-semibold text-center">En la próxima semana nos pondremos en contacto contigo para comunicarte si has sido seleccionado para utilizar tuParty</p>
    </div>   
</template>

<script>

import { useRoute } from 'vue-router';
  
  export default {
    name: 'DJLogin',
    components: {
    },
    setup() {
      const route = useRoute();
      const name = route.params.name;

      

      return {
        name
      }
    }
  }
  </script>
  