<template>
  <div v-for="n in 4" :key="n" class="relative w-40 h-40 mt-4 rounded-2xl bg-opacity-10 bg-tp-yellow">
    <div class="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-12">
      <div class="w-16 h-5 bg-opacity-40 bg-tp-yellow mb-2 rounded animate-pulse"></div>
      <div class="w-24 h-4 bg-opacity-20 bg-tp-yellow rounded opacity-80 animate-pulse"></div>
    </div>
    <div class="absolute top-2 right-2 bg-opacity-10 bg-tp-yellow rounded-full w-6 h-6 animate-pulse"></div>
  </div>
</template>

<script>

export default {
  name: 'PopularSongSkeleton'
};
</script>
