<template>
    <div class="flex flex-col items-center justify-between pt-16 pb-32 h-screen">
    <div class="flex flex-col gap-10" :class="{'z-20': !isDJLoginVisible}">
      <NavBar/>
      <p class="text-tp-yellow text-4xl font-semibold text-center">Zona DJ</p>
      </div>
      <div class="flex flex-col items-center gap-6 w-44" :class="{'z-20': !isDJLoginVisible}">
        <button class="border-2 border-tp-yellow bg-tp-black text-tp-yellow font-bold text-lg py-2 rounded-full w-full" @click="openLoginModal">Entra</button>
        <button class="border-2 border-tp-yellow bg-tp-yellow text-tp-black font-bold text-lg py-2 rounded-full w-full" @click="openRegisterModal">Regístrate</button>
      </div>
      <DJLoginModal v-if="isDJLoginVisible" :class="[{'transition-opacity duration-300 opacity-100 z-20': isDJLoginVisible}, {'opacity-0': !isDJLoginVisible}]" @onCloseModal="closeLoginModal"/>
      <DJRegisterModal v-if="isRegisterModalVisible" :class="[{'transition-opacity duration-300 opacity-100 z-20': isRegisterModalVisible}, {'opacity-0': !isRegisterModalVisible}]" @onCloseModal="closeRegisterModal"/>
    </div>
    <DJLoginModal :class="[{'transition-opacity duration-300 opacity-100 z-20': isDJLoginVisible}, {'opacity-0': !isDJLoginVisible}]" @onCloseModal="closeLoginModal"/>
  </template>
  
  <script>
  import { ref } from 'vue';
  import logo from '@/assets/images/logo.png'
  import DJLoginModal from '../../components/DJ/DJLoginModal.vue';
  import NavBar from '@/components/Common/NavBar.vue';
  import DJRegisterModal from '../../components/DJ/DJRegisterModal.vue';
  
  export default {
    name: 'DJLogin',
    components: {
      DJLoginModal,
      NavBar,
      DJRegisterModal
    },
    setup() {
      const isDJLoginVisible = ref(false);
      const isRegisterModalVisible = ref(false);

      const openLoginModal = () => {
        isDJLoginVisible.value = true;
      }

      const closeLoginModal = () => {
        isDJLoginVisible.value = false
      };

      const openRegisterModal = () => {
        isRegisterModalVisible.value = true;
      }

      const closeRegisterModal = () => {
        isRegisterModalVisible.value = false;
      }

      return {
        logo,
        openLoginModal,
        closeLoginModal,
        isDJLoginVisible,
        openRegisterModal,
        isRegisterModalVisible,
        closeRegisterModal
      }
    }
  }
  </script>
  