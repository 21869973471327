<template>
  <div class="rounded-full border-2 flex justify-center items-center" :class="[{'w-12 h-12 border-tp-yellow bg-tp-black': selected && !ranking}, {'w-10 h-10 border-tp-yellow bg-tp-black': ranking && podiumSong}, {'w-8 h-8 border-tp-yellow bg-tp-black': ranking && !mySong}, {'w-10 h-10 border-tp-yellow bg-tp-black': mySong}, {'w-0 h-0': !selected}]">
    <img v-if="!ranking" :src="rocket" alt="rocket-icon" class="w-6">
    <img v-else-if="ranking && podiumSong" :src="rocket" alt="rocket-icon" class="w-4">
    <img v-else-if="ranking && !mySong" :src="rocket" alt="rocket-icon" class="w-3.5">
    <img v-else-if="ranking && mySong" :src="rocket" alt="rocket-icon" class="w-3.5">
  </div>
</template>

<script>
import rocket from '@/assets/images/rocket-icon.png'
import blackRocket from '@/assets/images/rocket-icon-black.png'
export default {
  name: 'BoostButton',
  props: {
    selected: Boolean,
    ranking: Boolean,
    mySong: Boolean,
    rankingSong: Boolean,
    podiumSong: Boolean
  },
  setup() {
    return {
      rocket,
      blackRocket
    };
  }
};
</script>