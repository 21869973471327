<template>
  <div class="relative flex justify-between gap-2 items-center w-full bg-tp-black rounded-xl p-3 border-2 border-tp-yellow" >
    <div class="flex justify-between items-center gap-3">
      <img class="w-14 rounded-lg object-cover border-2 border-tp-yellow" :src="image" alt="">
      <div class="flex flex-col font-semibold leading-none text-tp-yellow">
        <p class="text-lg truncate" :class="payment ? 'w-36 xs:w-44' : 'w-40 xxs:w-44 xs:w-52'">{{ song }}</p>
        <p class="text-sm opacity-80 w-40 xxs:w-48 xs:w-52 truncate">{{ artist }}</p>
      </div>
    </div>
    <div v-if="payment" class="w-14 rounded-full bg-tp-yellow">
        <p class="font-bold text-center text-sm py-1 text-tp-black">{{ votes }}</p>
    </div>
    <div v-if="!payment" class="text-tp-black rounded-full w-6 h-6 bg-tp-yellow font-bold flex justify-center items-center">+</div>
  </div>
</template>

<script>

export default {
    name: 'SongCard',
    props: {
        song: String,
        artist: String,
        image: String,
        votes: Number,
        payment: {
          type: Boolean,
          default: false
        }
    },
    setup () {
    }
}
</script>