import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import DJLogin from '@/views/DJ/DJLogin.vue';
import DJPrivateArea from "@/views/DJ/DJPrivateArea.vue";
import DJHome from "@/views/DJ/DJHome.vue";
import DJPastSessions from "@/views/DJ/DJPastSessions.vue";
import SessionDetails from '@/views/DJ/SessionDetails.vue'
import DJGeneralVotings from "@/views/DJ/DJGeneralVotings.vue";
import DJRanking from '@/views/DJ/DJRanking.vue'
import AddSong from "@/views/AddSong.vue";
import ConfirmPayment from '@/views/ConfirmPayment.vue'
import Lounge from '@/views/Lounge.vue'
import StripePayment from '@/components/Payment/StripePayment.vue';
import DJActiveGeneralVoting from '@/views/DJ/DJActiveGeneralVoting.vue';
import DJAskedSignUp from '@/views/DJ/DJAskedSignUp.vue';
import DJResetPassword from '@/views/DJ/DJResetPassword.vue';


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/lounge/:id/add-song",
    name: "AddSong",
    component: AddSong,
  },
  {
    path: "/dj/login",
    name: "DJLogin",
    component: DJLogin,
  },
  {
    path: "/dj/home",
    name: "DJHome",
    component: DJHome,
  },
  {
    path: "/dj/private-area",
    name: "DJPrivateArea",
    component: DJPrivateArea,
  },
  {
    path: "/dj/past-sessions",
    name: "DJPastSessions",
    component: DJPastSessions,
  },
  {
    path: "/dj/past-sessions/session/:id",
    name: "SessionDetails",
    component: SessionDetails,
  },
  {
    path: "/dj/create-ranking",
    name: "DJCreateRanking",
    component: AddSong,
  },
  {
    path: "/dj/active-session/:id/general-votings",
    name: "DJGeneralVotings",
    component: DJGeneralVotings,
  },
  {
    path: "/dj/active-session/:id/create-general-voting",
    name: "DJCreateGeneralVoting",
    component: AddSong,
  },
  {
    path: "/dj/active-session/:id/ranking",
    name: "DJRanking",
    component: DJRanking,
  },
  {
    path: "/dj/active-session/:id/activeVoting",
    name: "DJActiveGeneralVoting",
    component: DJActiveGeneralVoting,
  },
  {
    path: "/lounge/:id/confirm-payment",
    name: "ConfirmPayment",
    component: ConfirmPayment,
  },
  {
    path: "/lounge/:id",
    name: "Lounge",
    component: Lounge,
  },
  {
    path: "/lounge/:id/song/:songId/pay/:votePlan/:votationType",
    name: "Payment",
    component: StripePayment,
  },
  {
    path: "/dj/new/:name",
    name: "DJAskedSignUp",
    component: DJAskedSignUp,
  },
  {
    path: "/dj/reset-password",
    name: "DJResetPassword",
    component: DJResetPassword,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Si hay una posición guardada (al navegar hacia atrás/adelante), úsala
    if (savedPosition) {
      return savedPosition;
    } else {
      // De lo contrario, desplázate hacia la parte superior de la página
      return { top: 0 };
    }
  },
});

export default router
