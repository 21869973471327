import axios from 'axios';
import router from '../router';
import store from '../store';
import getSuperCookie from '../helpers/superCookie';

const createApiService = () => {
  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_BASE_URL, 
  });

  axiosInstance.interceptors.request.use(async config => {
    const supercookie = await getSuperCookie();
    config.headers['superCookie'] = supercookie;
    return config;
  });

  
  axiosInstance.interceptors.response.use(
    response => response, 
    async error => {
      if (error.response?.data?.error == "ERR_TERMS_ACCEPTANCE") {
        const value = await store.dispatch('showTermsModal', error.response?.data?.data);
        const response = await axiosInstance.post('terms/accept', {
          thirdPartyCookies: value
        });
        store.dispatch('hideTermsModal');
        if (response.status == 200) {
          return axiosInstance(error.config);
        }
      }
      if (error.response && error.response.status === 401) {        
        router.push('/dj/login');
      }
      else if (error.response && error.response.status === 403) {        
        router.push('/dj/reset-password');
      }
      console.log(error)
      return Promise.reject(error); 
    }
  );

  return axiosInstance;
};

export default createApiService;
