<template>
    <div class="flex flex-col items-center justify-between pt-16 pb-32 h-screen">
      <div class="flex flex-col gap-10 z-20">
        <NavBar />
        <p class="text-tp-yellow text-4xl font-semibold text-center">Restablecer Contraseña</p>
      </div>
      <div class="flex flex-col items-center gap-6 w-44 z-20">
        <input 
          type="password" 
          v-model="newPassword" 
          placeholder="Nueva contraseña" 
          class="border-2 border-tp-yellow bg-tp-black text-tp-yellow font-bold text-lg py-2 px-4 rounded-full w-full"
        />
        <input 
          type="password" 
          v-model="confirmPassword" 
          placeholder="Repetir nueva contraseña" 
          class="border-2 border-tp-yellow bg-tp-black text-tp-yellow font-bold text-lg py-2 px-4 rounded-full w-full"
        />
        <button 
          class="bg-tp-yellow text-tp-black font-bold text-lg py-2 rounded-full w-full" 
          @click="resetPassword"
        >
          Confirmar
        </button>
      </div>
      <div v-if="errorMessage" class="text-red-500 text-lg mt-4">
        {{ errorMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import NavBar from '@/components/Common/NavBar.vue';
  import { userResetPassword } from '../../service/djService';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'DJResetPassword',
    components: {
      NavBar,
    },
    setup() {
      const newPassword = ref('');
      const confirmPassword = ref('');
      const errorMessage = ref('');
      const router = useRouter();
  
      const resetPassword = async () => {
        if (newPassword.value === '' || confirmPassword.value === '') {
          errorMessage.value = 'Por favor, completa ambos campos';
        } else if (newPassword.value !== confirmPassword.value) {
          errorMessage.value = 'Las contraseñas no coinciden';
        } else {
          errorMessage.value = '';
          const response = await userResetPassword(newPassword.value)
          if (response.result === true) {
            console.log("success")
            router.push("/dj/login")
          } else {
            console.log(response.error)
          }
        }
      };
  
      return {
        newPassword,
        confirmPassword,
        errorMessage,
        resetPassword
      };
    }
  };
  </script>
  