<template>
  <button class="flex items-center justify-center rounded-full " :class="[{'bg-tp-yellow': !darkbg}, {'bg-tp-black': darkbg}, {'p-1 w-8': small}, {'p-2 w-8 h-8': !small} , {'p-1.5 w-9 h-8': !small && isWithdraw}]">
    <img :class="{'w-5' : isWithdraw, 'w-3.5 h-3.5': !isWithdraw}" :src=image alt="">
  </button>
</template>
  
  <script>

  export default {
    name: 'DJActionButton',
    props: {
      image: String,
      darkbg: Boolean,
      small: Boolean,
      isWithdraw: Boolean
    },
    setup() {
    }
  }
  
  
  </script>
  