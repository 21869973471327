<template>
    <button class="flex flex-col gap-3 justify-center items-center border-2 border-tp-yellow bg-tp-black rounded-3xl w-full h-40" :class="{ 'opacity-50' : disabled }" :disabled="disabled">
        <p class="font-bold text-3xl text-tp-yellow">{{ text }}</p>
    </button>
</template>

<script>
export default {
    name: 'DJActiveSessionOption',
    props: {
      text: String,
      sessionsInfo:Object
    },
    setup() {
    
      return{
      }
    }
  }
</script>