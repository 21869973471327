<template>
  <div class="h-full flex flex-col justify-center items-center filter transition-all border-2 border-tp-yellow rounded-4xl"  :class="{'selected-shadow': mySong}">
    <div class="relative" @click="emitBoost">
      <BoostButton v-if="showBoostButton" class="z-10 absolute -top-2 -right-3" :class="{'animate-bounce': showBoostButton}" :ranking="true" :podiumSong="true" />
      <div>
        <img class="object-cover border-b-2 border-tp-yellow h-32 w-32 rounded-t-3xl overflow-hidden" :src="image">
      </div>
    </div>
    <div v-if="song == 'Tu canción'" class="flex flex-col justify-center py-2 h-16" :class="[isDJ ? 'text-tp-yellow' : 'text-tp-yellow', 'text-center']">
      <p class="font-semibold leading-none text-sm" :class="song == 'Tu canción' ? '' : 'w-24 truncate'">{{ song }}</p>
      <p class="font-semibold w-24 truncate text-sm">{{ artist }}</p>
    </div>
    <div v-else class="flex h-16 overflow-hidden" :class="[isDJ ? 'text-tp-yellow' : 'text-tp-yellow', 'text-center']">
      <p class="font-semibold leading-none text-sm w-24 m-auto">{{ cleanedText }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import BoostButton from '@/components/Common/BoostButton.vue';

export default {
  name: 'PodiumSong',
  components: {
    BoostButton
  },
  props: {
    mySong: Boolean,
    image: String,
    artist: String,
    song: String,
    showBoostButton: Boolean,
    position: Number,
    timerFinished: Boolean,
    isDJ: Boolean,
  },
  setup(props, { emit }) {
    const emitBoost = () => {
      emit('onBoostClick');
    };

    const cleanedText = computed(() => {
      const songWithoutParenthesis = props.song.replace(/\s*\([^)]*\)/g, '').trim(); 
      const concatenatedText = `${songWithoutParenthesis} - ${props.artist}`; 

      return concatenatedText.length > 30 ? `${concatenatedText.slice(0, 30)}...` : concatenatedText;
    });

    return {
      emitBoost,
      cleanedText,
    };
  }
};
</script>
<style>
.selected-shadow {
  filter: drop-shadow(1px 1px 20px #0D0D0D);
}
</style>
