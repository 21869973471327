import jsonp from 'jsonp';
import createApiService from './apiService';

const apiService = createApiService();

async function searchItunesTrack(query) {
  try {
    return new Promise((resolve, reject) => {
      jsonp(`https://api.deezer.com/search/track/?q=${query}&order=RATING_DESC&limit=6&output=jsonp`, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const formattedData = data.data.map(song => ({
            songId: song.id,
            image: song.album.cover_medium, 
            artist: song.artist.name,
            songName: song.title
          }));
        
          resolve(formattedData);
        }
      });
    });
  } catch (error) {
    console.error("Error while calling deezer API", error);
    throw error;
  }
}

async function trackInfo(songId) {
  try {
    return new Promise((resolve, reject) => {
      jsonp(`https://api.deezer.com/track/${songId}&output=jsonp`, (err, data) => {
        if (err) {
          reject(err);
        } else {
          console.log(data)
          const formattedData = {
            songId: data.id,
            image: data.album.cover_medium, 
            artist: data.artist.name,
            songName: data.title
          };
          resolve(formattedData);
        }
      });
    });
  } catch (error) {
    console.error("Error while calling iTunes API", error);
    throw error;
  }
}

async function getSuggestions(){

  try {
    const response = await apiService.get('/suggestions');
    const suggestions = response.data.result;

    const formattedData = suggestions.map(song => ({
      songId: song.songID,
      image: song.songInfo.cover, 
      artist: song.songInfo.artist_name,
      songName: song.songInfo.name
    }));
    console.log(formattedData)
    return formattedData;

  } catch (error) {
    console.error('Error fetching suggestions:', error);
    throw error;
  }

}


export { searchItunesTrack, trackInfo, getSuggestions };
