<template>
  <div id="confirm-payment" class="h-screen">
    <div class="flex flex-col justify-center items-center min-h-screen pb-12 px-12 space-y-8">
      <img :src="tickIconCircle" alt="tickIconCircle" class="text-tp-black w-40 h-40">
      <p class="text-center mx-4 font-bold text-3xl" v-html="text1"></p>
      <p class="text-center mx-4" v-html="text2"></p>
      <div class="buttons flex flex-col items-center space-y-6">
        <button @click="handleClick" class="w-80 py-3 px-4 bg-tp-yellow placeholder-tp-placeholder-black text-tp-black font-semibold rounded-2xl">
          Continúa con TuParty
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'ConfirmPayment',
  components: {
  },
  setup() {
    const tickIconCircle = require('@/assets/images/tick-icon-circle.png');
    const text1 = 'Pago confirmado'
    const text2 = '¡Gracias por adquirir tus votos!<br><br>Recuerda que solo se te cobrará en caso de que tu canción sea la elegida'

    let stripe = null
    onMounted(() => {
      checkStatus();
    });
    const initialize = async () => {
      stripe = await loadStripe("pk_test_51Ope8FHdxAPIqguouk0aGbUpvBHbY7r0vuKmj3s3owFeip3lzUYprfbO2L4GRETz1lAexLUebIIlSAKTMZDspxmP004F5faPYU");
    }
    // TODO: REFACTOR
    const checkStatus = async () => {
      await initialize();
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    };

    const handleClick = () => {
      let currentUrl = window.location.href;
      let newUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
      window.location.href = newUrl;
    };

    return {
      stripe,
      tickIconCircle,
      text1,
      text2,
      handleClick
    };
  }
};
</script>
