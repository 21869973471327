<template>
  <div :style="backgroundStyle" class="bg-cover bg-center h-screen flex flex-col items-center justify-end">
    <div class="relative flex items-center">
      <input v-model="qrId" class="py-3 pl-4 pr-10 bg-tp-yellow text-tp-black font-semibold rounded-2xl focus:outline-none mb-32" type="text" placeholder="Introduce ID QR">
      <button @click="processQRCode" class="absolute w-5 top-3.5 right-4">
        <img :src="arrow" alt="tickIcon" class="text-tp-black opacity-75 transform rotate-180">
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import createLoungeService from '@/service/loungeService';
import { useRouter } from 'vue-router';

export default {
  name: 'Home',
  setup() {
    const backgroundImage = require('@/assets/images/home.png');
    const tickIcon = require('@/assets/images/tick-icon.png');
    const arrow = require('@/assets/images/arrow-back.png');
    const qrId = ref(null);
    const backgroundStyle = ref({
      backgroundImage: `url(${backgroundImage})`
    });
    const { fetchLoungeIdFromQRId } = createLoungeService();
    const router = useRouter();

    const processQRCode = async () => {
      try {
        const loungeId = await fetchLoungeIdFromQRId(qrId.value);
        if (loungeId) {
          router.push(`lounge/${loungeId}`)
        }
      } catch (error) {
        console.error("Error processing QR Code:", error);
      }
    };

    return {
      backgroundImage,
      tickIcon,
      arrow,
      backgroundStyle,
      qrId,
      processQRCode
    };
  }
};
</script>

<style scoped>
  input::placeholder {
      color: #0D0D0D !important; /* Cambia este color al que desees */
      opacity: 0.75 !important; /* Asegúrate de establecer la opacidad si es necesario */
  }
</style>
