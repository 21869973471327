<template>
  <div class="modal-overlay">
    <div class="relative w-full px-6">
      <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
        <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
      </div>
      
      <div class="flex flex-col gap-8 border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
        <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Debe sonar</p>
        <div class="flex flex-col gap-4 px-6">
            <p class="text-tp-yellow text-base">La siguiente canción que debes poner es: <br> <span class=" underline">{{ songToShow.songName }} - {{ songToShow.artist }}</span></p>
          </div>
        <PodiumSong 
          :image="songToShow.image" 
          :artist="songToShow.artist" 
          :song="songToShow.songName" 
          :showBoostButton="false" 
          :position="1"  
          :class="'mx-auto z-20'"
          :isDJ ="true"
        />          
      </div>
    </div>
  </div>
</template>

<script>
import close from '@/assets/images/black-close-icon.png';
import info from '@/assets/images/info-icon.png';
import PodiumSong from '@/components/Ranking/PodiumSong.vue';

export default {
  name: 'DJShouldPlaySongModal',
  components: {
    PodiumSong
  },
  props: {
    songToShow: Object
  },
  setup(props, { emit }) {

    const handleCloseModal = () => {
      emit('onCloseModal');
    }
    return {
      close,
      info,
      handleCloseModal,
    }
  }
};
</script>
