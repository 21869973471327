import { createStore } from 'vuex'

export default createStore({
  state: {
    loungeId: '',
    acceptedTerms: null,
    showTermsModal: false,
  },
  mutations: {
    setLoungeId(state, id) {
      state.loungeId = id;
    },
    setAcceptedTerms(state, value) {
      state.acceptedTerms = value;
    },
    setShowTermsModal(state, value) {
      state.showTermsModal = value
    }
  },
  actions: {
    updateLoungeId({ commit }, id) {
      commit('setLoungeId', id);
    },
    acceptTerms({ commit }) {
      commit('setAcceptedTerms', true);
      this.resolveTermsModal(true);
    },
    showTermsModal({ commit }) {
      commit('setShowTermsModal', true);
      return new Promise((resolve, reject) => {
        this.resolveTermsModal = resolve;
        this.rejectTermsModal = reject;
      });
    },
    hideTermsModal({ commit }) {
      commit('setShowTermsModal', false);
    }
  },
  getters: {
    getLoungeId: state => state.loungeId
  }
})
