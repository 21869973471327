import createLoungeService from './loungeService';

function votingService(loungeID) {
  const loungeService = createLoungeService(loungeID);

  return {
    async vote(song) {
      try {
        return await loungeService.request('post', '/voting/vote', { songID: song });
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingFreeBoost(songID) {
      try {
        return await loungeService.request('post', '/ranking/boost/free', { songID });
      } catch (error) {
        console.error("Error while applying boost:", error);
        throw error;
      }
    },

    async votingPayment(object) {
      try {
        return await loungeService.request('post', '/voting/boost/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    },

    async rankingPayment(object) {
      try {
        return await loungeService.request('post', '/ranking/payment', object);
      } catch (error) {
        console.error("Error while casting vote:", error);
        throw error;
      }
    }
  };
}

export default votingService;
