<template>
  <div class="border-2 border-tp-yellow rounded-2xl">
    <div class="bg-tp-yellow rounded-t-xl py-3">
      <p class="text-tp-black text-center font-bold">Mi perfil</p>
    </div>
    <div class=" flex flex-col gap-4 bg-tp-black rounded-b-xl px-8 py-6">
      <DJInput :icon=user label="Nombre" :model="djData.stage_name" />
      <!-- TODO :actionIcon=pencil-->
      <DJInput :icon=mail label="Correo" :model="djData.email" />
      <DJInput :icon=dollar label="Saldo actual" :model="djData.saldo.toFixed(2)" :actionIcon=withdraw @click="handleWithdrawal" :isWithdraw="true" />
      <!-- TODO :actionIcon=withDraw-->
    </div>
  </div>
  <GenericInfoModal 
    v-if="cantWithdrawModalVisible"
    :title="'Retirada'"
    :message="'Podrás solicitar una retirada cuando tengas más de 50 euros de saldo disponibles'"
    :buttonText="'Entendido'"
    @onCloseModal="handleCloseModal" 
  />
  <GenericInfoModal 
    v-if="withdrawalRequestedModalVisible"
    :title="'Retirada'"
    :message="'Nos pondremos en contacto contigo lo antes posible. Por favor, no envíes otra solicitud durante el periodo de espera para evitar la acumulación'"
    :buttonText="'Entendido'"
    @onCloseModal="handleCloseModal" 
  />    
</template>
  
  <script>
  import DJInput from '@/components/DJ/DJInput.vue';
  import mail from '@/assets/images/mail.png';
  import pencil from '@/assets/images/pencil.png';
  import dollar from '@/assets/images/dollar.png';
  import withdraw from '@/assets/images/money-bag.png';
  import user from '@/assets/images/user-outline.png';
  import { ref} from 'vue';
  import { sendAskedWithdrawal } from '../../service/djService';
  import GenericInfoModal from '../Common/GenericInfoModal.vue';

  export default {
    name: 'DJProfile',
    components: {
      DJInput,
      GenericInfoModal
    },
    props: {
      djData: Object
    },
    setup(props) {
      const saldoIsBiggerThan50 = ref (props.djData.saldo > 50);

      const cantWithdrawModalVisible = ref (false);
      const withdrawalRequestedModalVisible = ref (false);
      
      function updateProfile() {
        alert('Perfil actualizado');
      }
      
      function handleWithdrawal() {
        if (!saldoIsBiggerThan50.value){
          cantWithdrawModalVisible.value = true;
        } else{
          sendAskedWithdrawal(props.djData.email)
          withdrawalRequestedModalVisible.value = true;
        }
        
      }

      const handleCloseModal = () => {
        cantWithdrawModalVisible.value = false;
        withdrawalRequestedModalVisible.value = false;
      };

      return {
        updateProfile,
        pencil,
        mail,
        dollar,
        user,
        withdraw,
        handleWithdrawal,
        cantWithdrawModalVisible,
        handleCloseModal,
        withdrawalRequestedModalVisible
      }
    }
  }
  
  
  </script>
  