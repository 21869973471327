<template>
  <div class="flex flex-col gap-10 items-center">
    <div class="simple-spinner"></div>
  </div>
  
</template>

<script>
export default {
  name: 'SimpleSpinner',
};
</script>

<style>
.simple-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #F9F871;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>