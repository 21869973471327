<template>
  <div class="flex justify-center py-4">
    <p class="font-bold text-5xl">TuParty</p>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToUrl(url) {
      if (url) {
        window.location.href = url;
      }
    }
  }
};
</script>