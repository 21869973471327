// loungeService.js
import createApiService from './apiService';
import store from '../store';

function createLoungeService(loungeId) {
  const apiService = createApiService();

  const request = async (method, endpoint, data) => {
    try {
      const url = `/lounge/${loungeId}${endpoint}`;
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await apiService.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error(`Error during ${method} request to ${endpoint}:`, error);
      throw error;
    }
  };

  const fetchLoungeIdFromQRId = async (qrId) => {
    try {
      const response = await apiService.get(`/qrAccess/${qrId}`);
      if (response.data.loungeID){
        const loungeId = response.data.loungeID;
        store.dispatch('updateLoungeId', loungeId);
        return loungeId;
      }
    } catch (error) {
      console.error('Error fetching Lounge ID:', error);
      throw error;
    }
  };

  const fetchLoungeDetails = async (loungeId) => {
    try {
      const response = await apiService.get(`/lounge/${loungeId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching lounge details:', error);
      throw error;
    }
  };

  return {
    request,
    fetchLoungeIdFromQRId,
    fetchLoungeDetails
  };
}

export default createLoungeService;
