<template>
    <button class="flex flex-col gap-3 justify-center items-center bg-tp-yellow rounded-3xl w-full h-40" :class="{ 'opacity-50' : disabled }" :disabled="disabled">
        <p class="font-bold text-3xl text-tp-black">{{ text }}</p>
        <div v-if="balance" class="flex items-center justify-center gap-1 bg-tp-black py-3 px-5 rounded-xl">
            <img class="w-6 h-6" :src="moneyBag" alt="">
            <p class="text-tp-yellow font-bold">{{ balance }}€</p>
        </div>
    </button>
</template>

<script>
import moneyBag from '@/assets/images/yellow-money-bag.png';
export default {
    name: 'DJHomeOption',
    components: {

    },
    props: {
      text: String,
      balance: Number,
      disabled: Boolean
    },
    setup() {
    
      return{
        moneyBag
      }
    }
  }
</script>