<template>
    <div class="modal-overlay" @click.self="emitCloseModal"> 
      <div class="relative w-full px-6">
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
            <img class="w-3 h-3" :src="close" alt="close-icon" @click="emitCloseModal">
        </div>
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
            <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">¿Cerrar sesión?</p>
            <p class="text-tp-yellow font-semibold px-6">No podrás revertirlo y se cancelarán todos los pagos no cobrados hasta ahora</p>
            <div class="flex gap-4 w-full px-6">
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitCloseSession">
                    <p class="text-tp-black text-sm font-bold">Cerrar sesión</p>
                </button>
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitCloseModal">
                    <p class="text-tp-black text-sm font-bold">Cancelar</p>
                </button>
            </div>
        </div>
      </div> 
    </div> 
  </template>
  

<script>

import close from '@/assets/images/black-close-icon.png';

export default {
    name: 'DJCloseSessionModal',
    setup( props,{ emit }) {

        const emitCloseSession = () => {
            emit('onCloseSession'); 
        };

        const emitCloseModal = () => {
            emit ('onCloseModal');
        }

        return {
            close,
            emitCloseSession,
            emitCloseModal
        }
    }
}
</script>

<style scoped>
.modal-overlay { 
  position: fixed;
  top: 0;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 428px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
  