<template>
    <div class="relative w-40 border-2 border-tp-yellow  rounded-2xl">
        <img class="h-full w-full rounded-2xl" :src="image" alt="">
        <div class="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-24 rounded-md flex flex-col justify-center items-center bg-tp-black border-2 border-tp-yellow font-semibold text-sm py-1">
            <p class="text-center w-20 truncate">{{ song }}</p>
            <p class="text-center w-20 truncate">{{ artist }}</p>
        </div>
        <div class="absolute top-2 right-2 bg-tp-black rounded-full w-6 h-6 font-bold flex justify-center items-center border-2 border-tp-yellow">+</div>
    </div>
</template>

<script>

export default {
    name: 'PopularSong',
    props: {
        song: String,
        artist: String,
        image: String
    },
}
</script>