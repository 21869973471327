<template>
  <div v-for="n in 4" :key="n" class="relative flex justify-between gap-2 items-center w-full bg-opacity-10 bg-tp-yellow rounded-xl p-3">
    <div class="flex justify-between items-center gap-6">
      <div class="w-14 h-14 rounded-lg bg-opacity-30 bg-tp-yellow animate-pulse"></div>
      <div class="flex flex-col font-semibold leading-none text-tp-yellow">
        <div class="w-36 h-5 bg-opacity-40 bg-tp-yellow mb-2 rounded animate-pulse"></div>
        <div class="w-52 h-4 bg-opacity-20 bg-tp-yellow rounded opacity-80 animate-pulse"></div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'SongCardSkeleton'
}
</script>