<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
        <div class="modal-content relative w-full px-6 flex justify-center items-center">
            <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
                <img class="w-3 h-3" :src="close" alt="close-icon" @click="handleCloseModal">
            </div>
            <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
                <div class="flex flex-col gap-4">
                    <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Registro</p>
                    <form class="flex flex-col gap-4 px-6" @submit.prevent="signUp">
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1 w-full">
                            <label class="text-xl" for="username">Nombre</label>
                            <div class="flex items-center gap-2 w-full">
                                <img :src=user alt="" class="w-5 h-5">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="username" placeholder="Juan" v-model="userData.name">
                            </div>
                            <img :src=line alt="" class="w-full">
                            <span v-if="!userData.name && submitted" class="text-red-500 text-sm">Nombre es obligatorio</span>
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">Apellido</label>
                            <div class="flex items-center gap-2 w-full">
                                <img :src=user alt="" class="w-5 h-5">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="username" placeholder="Sánchez" v-model="userData.surname">
                            </div>
                            <img :src=line alt="" class="w-full">
                            <span v-if="!userData.surname && submitted" class="text-red-500 text-sm">Apellido es obligatorio</span>
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">Teléfono</label>
                            <div class="flex items-center gap-2 w-full">
                                <img :src=phone alt="" class="w-5 h-5">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="username" placeholder="+34 612345678" v-model="userData.phone">
                            </div>
                            <img :src=line alt="" class="w-full">
                            <span v-if="!userData.phone && submitted" class="text-red-500 text-sm">Teléfono es obligatorio</span>
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">Email</label>
                            <div class="flex items-center gap-2 w-full">
                                <img :src=mail alt="" class="w-4 h-4">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="username" placeholder="ejemplo@correo.com" v-model="userData.email">
                            </div>
                            <img :src=line alt="" class="w-full">
                            <span v-if="!userData.email && submitted" class="text-red-500 text-sm">Email es obligatorio</span>
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">Elige una opción</label>
                            <div class="flex gap-4">
                                <div class="flex gap-1 items-center">
                                    <input type="radio" v-model="userData.role" class="custom-radio" value="DJ">
                                    <label>Soy DJ</label>
                                </div>
                                <div class="flex gap-1 items-center">
                                    <input type="radio" v-model="userData.role" class="custom-radio" value="disco">
                                    <label>Tengo una discoteca</label>
                                </div>
                            </div>
                            <span v-if="!userData.role && submitted" class="text-red-500 text-sm">Cómo usarás TuParty es obligatorio</span>
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="instagram">Instagram</label>
                            <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="instagram" placeholder="@Instagram" v-model="userData.instagram">
                            <img :src=line alt="" class="w-full">
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="instagram">Zona de influencia</label>
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="instagram" placeholder="Madrid" v-model="userData.location">
                            <img :src=line alt="" class="w-full">
                        </div>
                        <div class="flex flex-col items-start text-tp-yellow font-semibold gap-1">
                            <label class="text-xl" for="username">¿Cómo nos has conocido?</label>
                            <div class="flex items-center gap-2 w-full">
                                <input class="bg-tp-black text-base focus:outline-none focus:ring-0 font-semibold w-full" type="text" id="username" placeholder="Instagram, un DJ, web..." v-model="userData.referral">
                            </div>
                            <img :src=line alt="" class="w-full">
                        </div>           
                        <button type="submit" 
                            class="bg-tp-yellow text-tp-black font-bold text-lg px-10 py-2 rounded-full mt-4"
                            >Solicitar acceso
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, computed } from 'vue';
    import mail from '@/assets/images/mail.png';
    import line from '@/assets/images/green-line.png';
    import close from '@/assets/images/black-close-icon.png';
    import user from '@/assets/images/user-outline.png';
    import phone from '@/assets/images/phone.png';
    import { useRouter } from 'vue-router';
    import { sendLeadEmail } from '../../service/djService';

    export default {
        name: 'DJRegisterModal',
        setup(props, { emit }) {
            const router = useRouter();
            const submitted = ref(false);


            const userData = ref({
                name: '',
                surname: '',
                email: '',
                role:'',
                phone:'',
                referral:'',
                instagram:'',
                location:''
            });

            const isFormValid = computed(() => {
                return userData.value.name && userData.value.surname && userData.value.email &&
                    userData.value.phone && userData.value.role;
            });

            
            const signUp = async () => {
                console.log("entra")
                submitted.value = true;
                if (isFormValid.value){
                    await sendLeadEmail(userData.value);
                    router.push(`/dj/new/${userData.value.name}`);
                }
                
            };

            const handleCloseModal = () => {
                emit('onCloseModal');
            }

            return {
                signUp,
                userData,
                mail,
                line,
                close,
                handleCloseModal,
                user,
                phone,
                isFormValid,
                submitted
            }
        }
    }
</script>

<style scoped>
input::placeholder {
    color: #f9f871 !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 50% !important;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 428px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 40px 0px;
}

.modal-content {
  overflow-y: auto;
  width: 100%;
}

.modal-content .close-button {
  position: sticky;
  top: 10px;
}

/* Ocultar el radio button nativo */
.custom-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Tamaño del círculo */
  height: 20px;
  border: 2px solid #f9f871; /* Borde inicial */
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}

.custom-radio:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #f9f871;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>