<template>
    <div class="modal-overlay" @click.self="handleCloseModal"> 
      <div class="relative w-full px-6">
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
            <img class="w-3 h-3" :src="close" alt="close-icon" @click="emitCloseModal">
        </div>
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
            <div class="flex flex-col gap-8">
                <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">¿Estás listo?</p>
                <p  class="text-tp-yellow font-semibold px-6">Cuando pasen los 5 minutos tendrás que reproducir la que acabe en primera posición.</p>
                <p  class="text-tp-yellow font-semibold px-6"> ¡Una vez que lo hagas asegurarás los ingresos de esa canción!</p>
                <div class="flex gap-4 w-full px-6">
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitLaunch">
                    <p class="text-tp-black font-bold text-sm">Lanzar</p>
                </button>
                <button 
                    class="bg-tp-yellow p-3 rounded-xl flex-1 whitespace-nowrap" 
                    @click="emitCloseModal">
                    <p class="text-tp-black font-bold text-sm">Cancelar</p>
                </button>
                </div>
            </div>
        </div>
      </div> 
    </div> 
  </template>
  

<script>

import close from '@/assets/images/black-close-icon.png';

export default {
    name: 'DJConfirmLaunchModal',
    setup(props, { emit }) {

        const emitLaunch = () => {
            emit('onLaunch'); 
        };

        const emitCloseModal = () => {
            emit ('onCloseModal');
        }

        const handleCloseModal = () => {
            emit('onCloseModal');
        }

        return {
            close,
            emitLaunch,
            handleCloseModal,
            emitCloseModal
        }
    }
}
</script>
  